import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactUsModel, SubmitEmailModel } from '../Models/BlossmModel';

@Injectable({
    providedIn: 'root'
})

export class BlossmService {
    constructor(private _http: HttpClient) { }
    rootUrl = environment.baseUrl;

    ContactUs(ContactUsModel: ContactUsModel) {
        return this._http.post(this.rootUrl + '/public-auth/ContactUs', ContactUsModel);
    }

    SubmitCompanyEmail(submitEmailModel: SubmitEmailModel) {
        return this._http.post(this.rootUrl + '/public-auth/SendCompanyEmail', submitEmailModel);
    }

}